import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {CSPostResponseModel, FinanceModel} from '@creditsnap/data-models';
import {APP_ENV_CONFIG} from "../index";

@Injectable({
	providedIn: 'root'
})
export class FinanceService {
	finalSummitCredentials = new Subject<any>();
	public credentials: any = '';
	API_COLLATERAL_URL = this.env.apiUrl + '/finance';
	API_POST_TO_CORE = this.env.apiUrl + '/finance/core/onboard';
	API_DEPOSIT = this.env.apiUrl + '/deposit';

	otherOwners$ = new Subject<any>();
	controllingManager$ = new Subject<any>();
	businessOfficer$ = new Subject<any>();

	constructor(
		private http: HttpClient,
		@Inject(APP_ENV_CONFIG) public env: any,
	) {
		this.finalSummitCredentials.subscribe(data => {
			this.credentials = data;
		});
	}

	financeSubmit(_applicationId: number, finance: FinanceModel): Observable<CSPostResponseModel> {
		return this.http.put<CSPostResponseModel>(this.API_COLLATERAL_URL + `/${_applicationId}`, finance);
	}

	postToCoreSubmit(_applicationId: number, finance: FinanceModel): Observable<CSPostResponseModel> {
		return this.http.put<CSPostResponseModel>(this.API_POST_TO_CORE + `/${_applicationId}`, finance || {});
	}

	proceedOnlineBanking(_applicationId: number): Observable<any> {
		return this.http.get<any>(this.API_DEPOSIT + `/account/applications/registerUrl/${_applicationId}`);
	}
}
