import {Injectable, NgModule} from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
	HTTP_INTERCEPTORS
} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, catchError, throwError} from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private router: Router) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status && error.status.toString().startsWith('5')) {
					this.router.navigate(['/thankyou']);
				}
				return throwError(() => error);
			})
		);
	}
}

@NgModule({
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
	]
})
export class SharedModule {}
