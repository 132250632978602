export const commonConfig: any = {
	logoName: "https://s3.amazonaws.com/creditsnapimages/ABC/logo.png",
	phoneNumber: "111-111-1111",
	productSelection: false, // true for first page to show product selection with images
	multiSteps: false,
	stepsLocation: "", //top or "blank for no progress bar"
	imageFlow: false,
	imageForCollateral: false,
	existingMemberPrompt: false,
	branding: {},
	askOTP: true,
	otpVerifyChannel: 'SMS', // SMS or SMS_EMAIL
	stepsCount: 3,
	showRatingsWidgets: false,
	defaultLoanAmount: 500,
	isCoBorrowerRequired: true,
	isCitizenshipRequired: false,
	existingMemberFields: {
		lastName: true,
		dateOfBirth: true,
		zipCode: false,
		last4SSN: true,
		visible: true,
		firstName: false,
		email: false,
		memberNumber: false,
		existingMemberText: ""
	},
	isPermanentResidentRequired: false,
	defaultRateType: "fixed",
	preEligibilityCheck: false,
	relationshipToApplicant: true,
	employmentTypeList: [
		{ name: "Employed", value: "F" },
		{ name: "Unemployed", value: "N" },
		{ name: "Self Employed", value: "S" },
		{ name: "Retired", value: "R" },
		{ name: "Student", value: "ST" }
	],
	vehicleUndecided: true,
	hardInquiryConsent: "modal", //modal
	consent1_individual: `By providing information about yourself in this pre-qualification and selecting the box to the left,
    you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        <li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
         obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
         information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
          not a final application for credit.</li>
        </ul>`,
	consent1_individual_teller: `By providing information about yourself in this pre-qualification and selecting the box to the left,
	you consent, acknowledge, and agree to the following:
		<ul>
		<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
		<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
		<li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
			obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
			information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
			not a final application for credit.</li>
		</ul>`,
	consent1_joint: `By providing information about yourself in this pre-qualification and selecting the box to the left,
            you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        <li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
        to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
        such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
        final application for credit.</li>
        </ul>`,
	consent1_skip_offer: `By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        </ul>`,
	consent2: "You consent to receive email, SMS or other marketing communications from ABC." +
		" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
		"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
		" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
		"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
		"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
	consent3_joint: "By selecting the box to the left, you agree that completing and submitting information in this pre-qualification on behalf " +
		"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
	consent3_joint_skip_offer: "By selecting the box to the left, you agree that completing and submitting information on behalf " +
		"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
	hardInquiryConsentText: "You authorize ABC to obtain your credit report from a credit reporting company. You acknowledge that this " +
		"is an application for credit and authorize ABC to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow ABC to contact you and offer you savings and benefits on other products.",
	hardInquiryConsentText_teller: "You authorize ABC to obtain your credit report from a credit reporting company. You acknowledge that this " +
		"is an application for credit and authorize ABC to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow ABC to contact you and offer you savings and benefits on other products.",
	hardInquiryConsentText_joint: "You authorize ABC to obtain your credit report and Joint Applicant's credit report   from a credit reporting company. You acknowledge that this " +
		"is an application for credit and authorize ABC to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow ABC to contact you and offer you savings and benefits on other products.",
	consentsConfig: {
		web: {
			consent1_individual: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
    		you consent, acknowledge, and agree to the following:
				<ul>
				<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
				 obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
				 information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
				  not a final application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent2_individual: {
				text:"You consent to receive email, SMS or other marketing communications from ABC." +
					" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
					"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
					" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
					"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
					"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
				required: false,
				consents: ['Marketing'],
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text:`By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        </ul>`,
				required: true,
				consents: ['eConsent'],
			},
			//preapproval
			consent4_individual: {
				text:'preapproval consent',
				required: true,
				consents: ['eConsentAndSoftInquiry'],
			},

			consent1_joint: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
            you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        <li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
        to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
        such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
        final application for credit.</li>
        </ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent2_joint: {
				text:"You consent to receive email, SMS or other marketing communications from ABC." +
					" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
					"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
					" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
					"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
					"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
				required: false,
				consents: ['Marketing'],
			},
			//skipoffer joint
			consent3_joint: {
				text:'Skip offer joint',
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent1_prescreen: {
				text:'',
				required: true,
				constents: 'ECONSENTANDSOFTINQUIRY,MARKETING,ECONSENT,SOFT_INQUIRY',
			},
			consent2_prescreen: {
				text:'',
				required: true,
				constents: 'ECONSENTANDSOFTINQUIRY,MARKETING,ECONSENT,SOFT_INQUIRY',
			},

		},
		branch: {
			consent1_individual: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
    		you consent, acknowledge, and agree to the following:
				<ul>
				<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
				 obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
				 information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
				  not a final application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent2_individual: {
				text:"You consent to receive email, SMS or other marketing communications from ABC." +
					" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
					"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
					" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
					"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
					"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
				required: false,
				consents: ['Marketing'],
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text:`By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        </ul>`,
				required: true,
				consents: ['eConsent'],
			},
			//preapproval
			consent4_individual: {
				text:'preapproval consent',
				required: true,
				consents: ['eConsentAndSoftInquiry'],
			},

			consent1_joint: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
            you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        <li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
        to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
        such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
        final application for credit.</li>
        </ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent2_joint: {
				text:"You consent to receive email, SMS or other marketing communications from ABC." +
					" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
					"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
					" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
					"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
					"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
				required: false,
				consents: ['Marketing'],
			},
			//skipoffer joint
			consent3_joint: {
				text:'Skip offer joint',
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent1_prescreen: {
				text:'',
				required: true,
				constents: 'ECONSENTANDSOFTINQUIRY,MARKETING,ECONSENT,SOFT_INQUIRY',
			},
			consent2_prescreen: {
				text:'',
				required: true,
				constents: 'ECONSENTANDSOFTINQUIRY,MARKETING,ECONSENT,SOFT_INQUIRY',
			},

		},
		teller: {
			consent1_individual: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
					you consent, acknowledge, and agree to the following:
						<ul>
						<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
						<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
						<li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
						 obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
						 information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
						  not a final application for credit.</li>
						</ul>`,
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
			},
			consent2_individual: {
				text:'Marketing Consent',
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text:`By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
					<ul>
					<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
					<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
					</ul>`,
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
			},
			//preapproval
			consent4_individual: {
				text:'preapproval consent',
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
			},
			consent1_joint: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
					you consent, acknowledge, and agree to the following:
				<ul>
				<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
				to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
				such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
				final application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
			},
			consent2_joint: {
				text:'',
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
			},
			//skipoffer joint
			consent3_joint: {
				text:"By selecting the box to the left, you agree that completing and submitting information on behalf " +
					"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
			},
		},

	},
	underwritingStatusMessages: {
		approved: {
			title: "Congratulations! Your application is now approved.",
			message: "Our team will reach out to you within the next business day with next steps. Thank you for your membership in ABC Credit Union."
		},
		pending: {
			title: "Your application is being processed.",
			message: "Our team will reach out to you within the next business day to review your application."
		},
		decline: {
			title: "Your application is being processed.",
			message: "Our team will reach out to you within the next business day to review your application."
		},
		docRequest: {
			title: "Documents Required For Application Processing",
			message: "Additional documents are required to successfully complete the processing of your application. Please upload the below documents."
		},
		default: {
			title: "Your application is being processed.",
			message: "Our team will reach out to you within the next business day to review your application."
		}
	},
	crossSellOffersPage: {
		title: "Congratulations!! We found additional personalized pre-qualified offers for you.",
		message: ""
	},
	footerConfig: {
		footerTop: {
			BGColor: "#5EB3E4",
			FontColor: "#000"
		},
		footerBottom: {
			BGColor: "#5EB3E4",
			FontColor: "#FFF",
			copyRight: "Creditsnap",
			phone: "111-111-1111"
		}
	},
	ssnPrompt: true,
	mailingAddress: true,
	minHousingCost: 350,
	iovationIntegration: false,
	socureAPIIntegration: false,
	crossCoreUserProfile: false,
	defaultIncome: 300,
	minMax: {
		loanAmount: {
			min: 5000,
			max: 9999999
		}
	},
	// fraudCheckOnFinalSubmit: true,
	poboxAllowed: false,
	promoCode: false,
	membershipEligibilityCriteria: {
		visible: true,
		membershipString: "<p style=\"font-size: 125%;\"><strong>{{institutionName}} membership is free and more accessible to you than you might think. You are eligible to join if:</strong></p>\n" +
			"\n" +
			"      <ul aria-labelledby=\"memberul\" class=\"orangesquare\">\n" +
			"        <li class=\"marginBottom10\">You are an employee or retiree from a Sponsor Company.</li>\n" +
			"        <li class=\"marginBottom10\">You are a family member of any existing MCU member.</li>\n" +
			"        <li class=\"marginBottom10\">You live, work, or attend school in Cumberland, Duplin, Robeson, Sampson, Surry, Wilkes or Yadkin counties.</li>\n" +
			"        <li class=\"marginBottom10\">You are a member of an affiliate association of MCU (requires the applicant to apply in person at a branch).</li>\n" +
			"      </ul>",
		familyMember: true,
		sponsorOrganization: true,
		sponsorOrganizationType: "search",
		live: true,
		work: true,
		worship: false,
		study: true
	},
	membershipEligibilityOptions: [
		{ value: "FAMILY_MEMBER", label: "Someone in my family is a member" },
		{
			value: "FAMILY_MEMBER_AFFILIATED_ORGANIZATION",
			label: "Someone in my family work in one of the sponsor organizations."
		},
		{ value: "AFFILIATED_ORGANIZATION", label: "I work in one of the sponsor organizations" },
		{ value: "ASSOCIATION_MEMBERSHIP_NEW", label: "New Association Membership." },
		{ value: "ASSOCIATION_MEMBERSHIP_EXISTING", label: "Existing Association Membership." },
		{ value: "LIVING_AREA", label: "I live in eligible county" },
		{ value: "WORK_AREA", label: "I work in eligible county" },
		{ value: "WORSHIP_AREA", label: "I worship in eligible county" },
		{ value: "LEARN_AREA", label: "I attend school in eligible county" }
	],
	redeemCodeFields: {
		lastName: true,
		zipCode: false,
		ssn: false,
		dob: true
	},
	offerTerms: [6, 12, 24, 36, 48, 60, 72, 84],
	residenceStatusList: [
		{ value: "OWN_MORTGAGE", label: "Mortgage" },
		{ value: "RENT", label: "Rent" },
		{ value: "OWN_FREE_CLEAR", label: "Own Free and Clear" },
		{ value: "LIVING_WITH_PARENTS", label: "Living with Parents" },
		{ value: "LIVING_WITH_SOMEONE", label: "Living with someone" },
		{ value: "OTHER", label: "Other" }
	],
	additionalIncome: {
		startDate: false,
		types: [
			{
				id: "AL",
				text: "Alimony"
			},
			{
				id: "CS",
				text: "Child Support"
			},
			{
				id: "I",
				text: "Investment Income"
			},
			{
				id: "S",
				text: "Social Security"
			},
			{
				id: "R",
				text: "Retirement Income"
			},
			{
				id: "RP",
				text: "Rental Property Income"
			},
			{
				id: "O",
				text: "Other"
			}
		]
	},
	companyEntityType: [
		{value: 'LLC', name: 'Limited Liability Company'},
		{value: 'LP', name: 'Limited Partnership'},
		{value: 'CCORP', name: 'C Corporation'},
		{value: 'SCORP', name: 'S Corporation'},
		{value: 'NPA', name: 'Non Profit Corporation'},
		{value: 'TRUST', name: 'Trust'},
		{value: 'ASSOC', name: 'Association'},
		{value: 'NPC', name: 'Non Profit Association'},
		{value: 'GP', name: 'General Partnership'},
		{value: 'SP', name: 'Sole Proprietorship'},
		{value: 'OTHER', name: 'Other'},
	],
	loanPurpose: {
		PP: {
			id: "PP",
			title: "Personal Loan",
			name: 'Personal Loan',
			icon: 'person_check',
			description: 'Personal Loan with monthly payments - for home improvement or other emergency needs',
			productName: "OTHER",
			isMLARequired: false,
			vehicleUndecided: false,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentStatus: true,
				employmentLength: false,
				additionalIncome: false
			},
			addOnProducts: {
				visible: false,
				productAtOffer: false
			},
			tradeLine: {
				required: false,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: false,
				type: "AUTO",
				yearMakeModelSelection: true
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			purposeCodeModal: true,
			purposeCodeList: [
				{ value: "PP^Attorney Fees", label: "Attorney Fees" },
				{ value: "PP^Collections", label: "Collections" },
				{ value: "PPDC^Debt Consolidation", label: "Debt Consolidation" },
				{ value: "PP^Funeral", label: "Funeral" },
				{ value: "PP^Home Upgrades", label: "Home Upgrades"},
				{ value: "PP^Medical Bills", label: "Medical Bills" },
				{ value: "PP^Vehicle Repairs", label: "Vehicle Repairs"},
				{ value: "PP^Vacation", label: "Vacation" },
				{ value: "PP^Wedding", label: "Wedding" },
				{ value: "PP^Other", label: "Other"},
			],
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			minAnnualIncomeCoBo: 0,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: false,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 22500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: false,
					desiredTerm: false,
					desiredTermSelection: false
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: "",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 32,
				purchaseIntroPeriod: 23,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 22,
				balanceTransferAPR: 12,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{ type: "W2", required: true }
			]
		},
		PLOC: {
			id: "PLOC",
			title: "Personal Line of Credit",
			name: 'Personal Line of Credit',
			icon: 'person_check',
			description: 'Revolving credit line for any emergency needs',
			productName: "OTHER",
			isMLARequired: false,
			vehicleUndecided: false,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			fraudCheckAPICall: "",
			addOnProducts: {
				visible: false,
				productAtOffer: false
			},
			tradeLine: {
				required: false,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: false,
				type: "AUTO",
				yearMakeModelSelection: true
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			purposeCodeModal: true,
			purposeCodeList: [
				{ value: "PP^Attorney Fees", label: "Attorney Fees" },
				{ value: "PP^Collections", label: "Collections" },
				{ value: "PPDC^Debt Consolidation", label: "Debt Consolidation" },
				{ value: "PP^Funeral", label: "Funeral" },
				{ value: "PP^Home Upgrades", label: "Home Upgrades"},
				{ value: "PP^Medical Bills", label: "Medical Bills" },
				{ value: "PP^Vehicle Repairs", label: "Vehicle Repairs"},
				{ value: "PP^Vacation", label: "Vacation" },
				{ value: "PP^Wedding", label: "Wedding" },
				{ value: "PP^Other", label: "Other"},
			],
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 22500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: false,
					desiredTerm: false
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: "",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 32,
				purchaseIntroPeriod: 23,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 22,
				balanceTransferAPR: 12,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{ type: "W2", required: true }
			]
		},
		PPDC: {
			id: "PPDC",
			title: "Personal Loan - Debt Consolidation",
			name: 'Personal Loan Debt Consolidation',
			icon: 'person_check',
			description: 'Consolidate all your personal loans and credit card debt into one single Consolidation Loan',
			productName: "OTHER",
			hideFromDropdown: false,
			isMLARequired: false,
			vehicleUndecided: false,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			fraudCheckAPICall: "",
			addOnProducts: {
				visible: false,
				debtProtection: false
			},
			tradeLine: {
				required: false,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: false,
				type: "AUTO",
				yearMakeModelSelection: true
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 22500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: false,
					desiredTerm: false
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: true,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: false,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: false,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: false,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: false,
					ownershipInterest: false
				}
			},
			crossSellPageSubTitle: "",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 32,
				purchaseIntroPeriod: 23,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 22,
				balanceTransferAPR: 12,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{ type: "W2", required: true }
			]
		},
		CR: {
			id: "CR",
			title: "Cashout Refinance - Own Title",
			name: 'Vehicle Refinance - Cashout',
			icon: 'directions_car',
			description: 'Cash out Refinance of your vehicle',
			productName: "AUTO",
			isMLARequired: false,
			vehicleUndecided: false,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			fraudCheckAPICall: "",
			addOnProducts: {
				visible: false,
				debtProtection: false
			},
			tradeLine: {
				required: false,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "retailValue",
			defaultLoanAmount: 22500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: true,
					desiredTerm: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: "",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 32,
				purchaseIntroPeriod: 23,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 22,
				balanceTransferAPR: 12,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{ type: "W2", required: true }
			]
		},
		R: {
			id: "R",
			title: "Vehicle Loan - Refinance",
			name: 'Vehicle Loan Refinance',
			icon: 'directions_car',
			description: 'Save on your monthly payment by refinancing your vehicle loan balance',
			productName: "AUTO",
			isMLARequired: false,
			isRefinance: true,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentStatus: false,
				employmentLength: true,
				additionalIncome: false
			},
			addOnProducts: {
				visible: true,
				productAtOffer: false
			},
			tradeLine: {
				required: true,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: true
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "retailValue",
			defaultLoanAmount: 22500,
			othersOnTitle: true,
			othersOnTitlePhone: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: true,
					desiredTerm: true,
					stateVehicleRegistration: false,
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true,
					driverLicense: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
					driverLicense: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment.",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 40,
				purchaseIntroPeriod: 20,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 23,
				balanceTransferAPR: 12,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{ type: "W2", required: true }
			],
			tradeInVehicle: false
		},
		R_PROMO: {
			id: "R_PROMO",
			title: "Vehicle Loan - Promotion",
			name: 'Vehicle Loan Refinance',
			icon: 'directions_car',
			description: 'Save on your monthly payment by refinancing your vehicle loan balance',
			productName: "AUTO",
			isMLARequired: false,
			isRefinance: true,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			fraudCheckAPICall: "",
			preScreenPage: {
				grossAnnualIncome: false,
				rentMortgagePmt: false
			},
			aboutYouPage: {
				employmentStatus: false,
				employmentLength: true,
				additionalIncome: false
			},
			addOnProducts: {
				visible: false
			},
			tradeLine: {
				required: true,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: true
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "retailValue",
			defaultLoanAmount: 22500,
			othersOnTitle: true,
			othersOnTitlePhone: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: true,
					desiredTerm: true,
					stateVehicleRegistration: false,
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true,
					driverLicense: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
					driverLicense: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment.",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 40,
				purchaseIntroPeriod: 20,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 23,
				balanceTransferAPR: 12,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{ type: "W2", required: true }
			],
			tradeInVehicle: false
		},
		AU: {
			id: "AU",
			title: "Used Vehicle Purchase",
			name: 'Used Vehicle Purchase',
			icon: 'directions_car',
			description: 'Get the best rate on your Used Vehicle Purchase Loan',
			productName: "AUTO",
			isMLARequired: false,
			vehicleUndecided: true,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentStatus: false,
				employmentLength: true,
				additionalIncome: false
			},
			addOnProducts: {
				visible: true,
				productAtOffer: true
			},
			tradeLine: {
				required: false,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "purchasePrice",
			defaultLoanAmount: 22500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: true,
					desiredTerm: true,
					stateVehicleRegistration: false,
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true,
					driverLicense: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
					driverLicense: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: "",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 32,
				purchaseIntroPeriod: 23,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 22,
				balanceTransferAPR: 12,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{ type: "W2", required: true }
			],
			tradeInVehicle: true
		},
		AN: {
			id: "AN",
			title: "New Vehicle Purchase",
			name: 'New Vehicle Purchase',
			icon: 'directions_car',
			description: 'Get the best rate on your New Vehicle Purchase Loan',
			productName: "AUTO",
			isMLARequired: false,
			vehicleUndecided: true,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentStatus: false,
				employmentLength: true,
				additionalIncome: false
			},
			addOnProducts: {
				visible: true,
				productAtOffer: false
			},
			tradeLine: {
				required: false,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true,
				years: "all" // all, new, old
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "retailValue",
			defaultLoanAmount: 22500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: true,
					desiredTerm: true,
					stateVehicleRegistration: false,
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					driverLicense: true,
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment.",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 0,
				purchaseIntroPeriod: "",
				purchaseAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%",
				balanceTransferIntroAPR: "",
				balanceTransferIntroPeriod: "",
				balanceTransferAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				cashAdvanceAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				penaltyAPRDesc: "Up to 20.49%.",
				foreignTransactionDesc: "1.00% of each foreign currency transaction in U.S. Dollars. " +
					"0.80% of each U.S. Dollar transaction that occurs in a foreign country",
				returnedPaymentFeeDesc: "Up to $28",
				latePaymentFeeDesc: "Up to $25"
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{ type: "W2", required: true }
			],
			tradeInVehicle: true
		},
		CC: {
			id: "CC",
			title: "New Credit Card",
			name: 'Credit Card',
			icon: 'credit_card',
			description: 'See how much you can qualify for - without impacting your credit',
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentStatus: true,
				employmentLength: false
			},
			addOnProducts: {
				visible: false,
				debtProtection: false
			},
			tradeLine: {
				required: false,
				multiselect: false,
				code: "CREDIT_CARD",
				tradeLineText: "Select the credit card loans that you want to consolidate",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "CREDIT_CARD",
				yearMakeModelSelection: true
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: false
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 25000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			offerVariationDetails: {
				VISA_PLATINUM: {
					imageUrl: "https://www.ABC.com/images/categories/personal/credit_card/cc-platinum.jpg",
					title: "Platinum Card",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCreditFormatted}} Credit Limit",
						"0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*",
						"No annual fee",
						"No balance transfer fee"
					],
					longInfo: "<ul>" +
						"<li>{{maxAvailableCreditFormatted}} Credit Limit</li>" +
						"<li>0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*</li>" +
						"<li>No annual fee</li>" +
						"<li>No balance transfer fee</li>" +
						"</ul>"
				},
				VISA_DIAMOND: {
					imageUrl: "https://www.ABC.com/images/categories/personal/credit_card/cc-diamond.jpg",
					title: "Diamond Cashback",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCreditFormatted}} Credit Limit",
						"0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*",
						"Earn unlimited 1.50% cash back",
						"Receive $200 Bonus Cash Back after $2,000 in qualified purchases within the first 90 days after account opening.",
						"No annual fee"
					],
					longInfo: "<ul>" +
						"<li>{{maxAvailableCreditFormatted}} Credit Limit</li>" +
						"<li>0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*</li>" +
						"<li>No annual fee</li>" +
						"<li>No balance transfer fee</li>" +
						"<li>Earn unlimited 1.50% cash back</li>" +
						"Receive $200 Bonus Cash Back after $2,000 in qualified purchases within the first 90 days after account opening.</li>" +
						"No annual fee</li>" +
						"No balance transfer fee" +
						"</ul>"
				}
			},
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: false,
					desiredTerm: false
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			cardImageSelector: {
				visible: true,
				cardImages: [
					{
						name: "4",
						subProductNames: ["VISA_DIAMOND", "VISA_PLATINUM"],
						image: "https://creditsnapimages.s3.amazonaws.com/ABC/WSU_CreditCard2.png"
					},
					{
						name: "5",
						subProductNames: ["VISA_DIAMOND", "VISA_PLATINUM"],
						image: "https://creditsnapimages.s3.amazonaws.com/ABC/WSU_CreditCard1.png"
					},
					{
						name: "1",
						subProductNames: ["VISA_PLATINUM"],
						image: "https://www.ABC.com/images/categories/personal/credit_card/cc-platinum.jpg"
					},
					{
						name: "2",
						subProductNames: ["VISA_DIAMOND"],
						image: "https://www.ABC.com/images/categories/personal/credit_card/cc-diamond.jpg"
					}
				]
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{ type: "Paystub", required: true },
				{ type: "W2", required: true },
				{ type: "Driving License", required: true },
				{ type: "Passport", required: true },
				{ type: "Loan Payoff Document", required: false },
				{ type: "Title", required: false },
				{ type: "Other", required: false }
			],
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment.",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 0,
				purchaseIntroPeriod: "",
				purchaseAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%",
				balanceTransferIntroAPR: "",
				balanceTransferIntroPeriod: "",
				balanceTransferAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				cashAdvanceAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				penaltyAPRDesc: "20.49%.",
				foreignTransactionDesc: "1.00% of each foreign currency transaction in U.S. Dollars. " +
					"0.80% of each U.S. Dollar transaction that occurs in a foreign country",
				returnedPaymentFeeDesc: "$28",
				latePaymentFeeDesc: "$25"
			}
		},
		CLI: {
			id: "CLI",
			title: "Existing Card Limit Increase",
			name: 'Credit Limit Increase',
			icon: 'credit_card',
			description: 'See how much Credit Limit you can qualify for - without impacting your credit',
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			skipOfferScreen: true,
			orderCreditBureau: false,
			fraudCheckAPICall: "",
			termBasedLoan: false,
			aboutYouPage: {
				employmentType: false,
				employmentLength: false,
				additionalIncome: false,
				selectCard: true,
				title: true,
				ssn: true,
				askCreditLimit: true
			},
			tradeLine: {
				required: false,
				multiselect: false,
				code: "OTHER",
				tradeLineText: "Select the credit card loans that you want to consolidate",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "OTHER",
				yearMakeModelSelection: true
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 25000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			offerVariationDetails: {
				VISA_PLATINUM: {
					imageUrl: "https://www.ABC.com/images/categories/personal/credit_card/cc-platinum.jpg",
					title: "Platinum Card",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCreditFormatted}} Credit Limit",
						"0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*",
						"No annual fee",
						"No balance transfer fee"
					],
					longInfo: "<ul>" +
						"<li>{{maxLoanAmount}} Credit Limit</li>" +
						"<li>0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*</li>" +
						"<li>No annual fee</li>" +
						"<li>No balance transfer fee</li>" +
						"</ul>"
				},
				VISA_DIAMOND: {
					imageUrl: "https://www.ABC.com/images/categories/personal/credit_card/cc-diamond.jpg",
					title: "Diamond Cashback",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCreditFormatted}} Credit Limit",
						"0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*",
						"Earn unlimited 1.50% cash back",
						"Receive $200 Bonus Cash Back after $2,000 in qualified purchases within the first 90 days after account opening.",
						"No annual fee"
					],
					longInfo: "<ul>" +
						"<li>{{maxAvailableCreditFormatted}} Credit Limit</li>" +
						"<li>0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*</li>" +
						"<li>No annual fee</li>" +
						"<li>No balance transfer fee</li>" +
						"<li>Earn unlimited 1.50% cash back</li>" +
						"Receive $200 Bonus Cash Back after $2,000 in qualified purchases within the first 90 days after account opening.</li>" +
						"No annual fee</li>" +
						"No balance transfer fee" +
						"</ul>"
				}
			},
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: false,
					desiredTerm: false
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			cardImageSelector: {
				visible: false,
				cardImages: []
			},
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment.",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 28,
				purchaseIntroPeriod: 23,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 22,
				balanceTransferAPR: 14,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			}
		},
		SSECURED: {
			id: "SSECURED",
			title: "Share Secured Card",
			name: 'Share Secured Loan',
			icon: 'person_check',
			description: 'Apply for a Share Secured Personal Loan',
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			skipOfferScreen: true,
			orderCreditBureau: false,
			fraudCheckAPICall: "",
			termBasedLoan: false,
			aboutYouPage: {
				employmentType: false,
				employmentLength: false,
				additionalIncome: false,
				selectCard: true,
				title: true,
				ssn: true,
				askCreditLimit: true
			},
			tradeLine: {
				required: false,
				multiselect: false,
				code: "OTHER",
				tradeLineText: "Select the credit card loans that you want to consolidate",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "OTHER",
				yearMakeModelSelection: true
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 25000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: false,
					desiredTerm: false
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment.",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 28,
				purchaseIntroPeriod: 23,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 22,
				balanceTransferAPR: 14,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			}
		},
		CCDC: {
			id: "CCDC",
			title: "New Card with Debt Consolidation",
			name: 'Credit Card Debt Consolidation',
			icon: 'credit_card',
			description: 'Consolidate all your card debt into one Card. See how much you can qualify for - without impacting your credit',
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			fraudCheckAPICall: "",
			addOnProducts: {
				visible: false,
				productAtOffer: false
			},
			tradeLine: {
				required: true,
				multiselect: true,
				code: "CREDIT_CARD",
				tradeLineText: "Select the credit card loans that you want to consolidate",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "CREDIT_CARD",
				yearMakeModelSelection: true
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: false
			},
			phone: "1-800-000-0000",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 25000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			othersOnTitle: false,
			othersOnTitlePhone: false,
			offerVariationDetails: {
				VISA_PLATINUM: {
					imageUrl: "https://www.ABC.com/images/categories/personal/credit_card/cc-platinum.jpg",
					title: "Platinum Card",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCreditFormatted}} Credit Limit",
						"0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*",
						"No annual fee",
						"No balance transfer fee"
					],
					longInfo: "<ul>" +
						"<li>{{maxLoanAmount}} Credit Limit</li>" +
						"<li>0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*</li>" +
						"<li>No annual fee</li>" +
						"<li>No balance transfer fee</li>" +
						"</ul>"
				},
				VISA_DIAMOND: {
					imageUrl: "https://www.ABC.com/images/categories/personal/credit_card/cc-diamond.jpg",
					title: "Diamond Cashback",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCreditFormatted}} Credit Limit",
						"0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*",
						"Earn unlimited 1.50% cash back",
						"Receive $200 Bonus Cash Back after $2,000 in qualified purchases within the first 90 days after account opening.",
						"No annual fee"
					],
					longInfo: "<ul>" +
						"<li>{{maxAvailableCreditFormatted}} Credit Limit</li>" +
						"<li>0% APR* intro rate on purchases for {{purchaseIntroPeriod}} months and on balance transfers for {{balanceTransferIntroPeriod}} months; after that, variable rate of {{balanceTransferAPR}} APR*</li>" +
						"<li>No annual fee</li>" +
						"<li>No balance transfer fee</li>" +
						"<li>Earn unlimited 1.50% cash back</li>" +
						"Receive $200 Bonus Cash Back after $2,000 in qualified purchases within the first 90 days after account opening.</li>" +
						"No annual fee</li>" +
						"No balance transfer fee" +
						"</ul>"
				}
			},
			cardImageSelector: {
				visible: true,
				cardImages: [
					{
						name: "4",
						subProductNames: ["VISA_DIAMOND", "VISA_PLATINUM"],
						image: "https://creditsnapimages.s3.amazonaws.com/ABC/WSU_CreditCard2.png"
					},
					{
						name: "5",
						subProductNames: ["VISA_DIAMOND", "VISA_PLATINUM"],
						image: "https://creditsnapimages.s3.amazonaws.com/ABC/WSU_CreditCard1.png"
					},
					{
						name: "1",
						subProductNames: ["VISA_PLATINUM"],
						image: "https://www.ABC.com/images/categories/personal/credit_card/cc-platinum.jpg"
					},
					{
						name: "2",
						subProductNames: ["VISA_DIAMOND"],
						image: "https://www.ABC.com/images/categories/personal/credit_card/cc-diamond.jpg"
					}
				]
			},
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: false,
					desiredTerm: false
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				declarationQuestions: {
					outstandingJudgements: true,
					bankrupt: true,
					foreClosed: true,
					lawsuit: true,
					obligatedLoanForeclosure: true,
					delinquentFederalDebt: true,
					payAlimony: true,
					downPaymentBorrowed: true,
					coMakerOrEndorser: true,
					propertyAsPrimaryResidence: true,
					ownershipInterest: true
				}
			},
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment."
		},

		BPP: {
			name: 'Business Loan',
			icon: 'currency_exchange',
			description: 'Business Loans for liquidity, equipment purchases and more.',
		},
		BCC: {
			name: 'Special Vehicle Loans',
			icon: 'credit_card',
			description: 'Business Credit Card.',
		},
		RV: {
			name: 'Recreation Vehicle (RV) Loan',
			icon: 'directions_boat',
			description: 'Ready to buy or refinance your RV? See your buying power without impacting your credit',
		},
		M: {
			name: 'Motorcycle Loan',
			icon: 'directions_boat',
			description: 'Buying or refinancing a Motorcycle? See your buying power without impacting your credit',
		},
		B: {
			name: 'Boat Loan',
			icon: 'directions_boat',
			description: 'Buying or refinancing a Boat? See your buying power without impacting your credit',
		},
		TFE: {
			name: 'Tractor or Farm Equipment Loan',
			icon: 'directions_boat',
			description: 'See how much you can qualify without impacting your credit',
		},
		TT: {
			name: 'Travel Trailer Loan',
			icon: 'directions_boat',
			description: 'See how much you can qualify without impacting your credit',
		},
	},
	steps: {
		1: {
			title: "Personal Details",
			fields: {
				name: true,
				email: false
			}
		},
		2: {
			title: "Bank Details",
			fields: {
				phone: true,
				address: false
			}
		},
		3: {
			title: "Extra details"
		}
	},

	loanCategories: {
		AUTO: {
			name: 'Vehicle Loans',
			icon: 'directions_car',
			description: 'Whether you are still planning  or ready to buy, see your instant offers.',
		},
		CREDIT_CARD: {
			name: 'Credit Cards',
			icon: 'credit_card',
			description: 'Get a new credit card or apply for a Credit LImit Increase on your current card',
		},
		OTHER: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Whether you need money for your next big home improvement project or for any other emergency situation.',
		},
		AUTO_SPECIALTY: {
			name: 'Special Vehicle Loans',
			icon: 'directions_boat',
			description: 'Looking to buy an RV or a boat or other specialty vehicle?',
		},
		
		BUSINESS_LENDING: {
			name: 'Business Loans',
			icon: 'currency_exchange',
			description: 'Business Loans, Business Cards and more.',
		},
	}
};
