import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgxOtpInputComponent, NgxOtpInputConfig} from 'ngx-otp-input';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProgressButtonOptions} from '@creditsnap/data-models';
import {NgxOtpStatus} from 'ngx-otp-input/lib/component/ngx-otp-input.model';
import {otpPostRequestBody, OtpService} from '@creditsnap/app-config';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: "creditsnap-otp-modal",
	templateUrl: "./otp-model.component.html",
	styleUrls: ["./otp-model.component.scss"]
})
export class OtpModelComponent implements OnInit {

	@ViewChild("otpInput") otpInput: NgxOtpInputComponent;

	// https://github.com/pkovzz/ngx-otp-input
	otpInputConfig: NgxOtpInputConfig = {
		otpLength: 6,
		autofocus: true,
		numericInputMode: true,
		classList: {
			input: "otp-model-class",
			inputFilled: "otp-model-filled-class",
			inputDisabled: "otp-model-disable-class",
			inputSuccess: "otp-model-success-class",
			inputError: "otp-model-error-class"
		}
	};
	barButtonOptions: ProgressButtonOptions = {
		active: false,
		text: "Validate",
		buttonColor: "primary",
		barColor: "#C1D72E",
		raised: true,
		stroked: false,
		mode: "indeterminate",
		value: 0,
		disabled: false,
		customClass: "validate-button"
	};

	phone: string;
	email: string;
	otpValue: any;
	otpInputStatus: NgxOtpStatus = null;
	resendOTPSeconds = 120;
	resendOtpEnableCount: number;
	invalidOTPError = false;
	OTP: string;
	invalidOTP: boolean;
	otpValidated: boolean;
	applicationId: number;
	blockListFlow = false;
	searchOtpRequired: boolean;
	lastName: string;
	dateOfBirth: Date;
	dob: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any,
				private otpService: OtpService,
				private _matSnackBar: MatSnackBar,
				public dialogRef: MatDialogRef<OtpModelComponent>) {
		this.phone = data.phone || data.contact;
		this.email = data.email;
		this.lastName = data.lastName;
		this.dateOfBirth = data.dateOfBirth;
		this.dob = data.dob;
		this.searchOtpRequired = data.searchOtpRequired;
		this.applicationId = data.applicationId;
		this.blockListFlow = data.blockListFlow || false;
		this.invalidOTPError = data.invalidOTPError || false;
	}

	ngOnInit(): void {
		this.resendOtpEnableCount = this.resendOTPSeconds;
		this.timer();
		console.log(this.otpInputConfig);
	}

	timer() {
		if (this.resendOtpEnableCount > 0) {
			this.resendOtpEnableCount--;
			setTimeout(() => {
				this.timer();
			}, 1000);
			return;
		}
	}

	validateOtp() {
		this.barButtonOptions.active = true;
		const otpArr = this.otpValue.filter((item) => item && item.length);
		if (otpArr.length !== 6) {
			this.barButtonOptions.active = false;
			this.otpInputStatus = "error";
			return;
		} else {
			this.otpInputStatus = "success";
			this.OTP = otpArr.join("");
			if(this.blockListFlow) { // flow which send otp on create app api
				this.verifyOTP();
			} else {
				this.dialogRef.close(this.OTP);
			}
		}
	}

	handeOtpChange(e) {
		this.otpValue = e;
	}

	async sendOTP() {
		const otpBody: otpPostRequestBody = this.data;
		this.otpService.sendOTP(otpBody)
			.subscribe((resp: any) => {
				console.log("SEND OTP RESP >>>>>>>>>", resp);
				if (resp && resp.code === 200) {
					this.resendOtpEnableCount = this.resendOTPSeconds;
					this.timer();
					this._matSnackBar.open("OTP sent.", "Success !!!", {
						duration: 3000
					});
				}
			}, error => {
				console.log(error);
				if (error.error.code.toString().startsWith("4")) {
					this._matSnackBar.open(error.error.message, "Warning !!!", {
						duration: 3000
					});
				} else {
					this._matSnackBar.open("Something went wrong!", "Warning !!!", {
						duration: 3000
					});
				}
			});
	}

	changeNumber() {
		this.dialogRef.close(false);
	}

	verifyOTP() {
		if(this.searchOtpRequired) {
			this.otpService.searchVerifyOTP(this.dateOfBirth, this.lastName, this.OTP, this.phone, this.email, this.dob)
			.subscribe((resp: any) => {
				console.log('VERIFY OTP RESP >>>>>>>>>', resp);
				if (resp && resp.code === 200) {
					this.barButtonOptions.active = false;
					this.invalidOTP = false;
					this.otpValidated = true;
					
					this.dialogRef.close(resp.applications);
				} else {
					this.barButtonOptions.active = false;
					this._matSnackBar.open('Please Enter Valid OTP.', 'Warning !!!', {
						duration: 3000,
					});
					this.invalidOTP = true;
					this.otpValidated = false;
				}
			}, err => {
				this.barButtonOptions.active = false;
				this._matSnackBar.open('Please Enter Valid OTP.', 'Error !!!', {
					duration: 3000,
					verticalPosition: 'top'
				});
				this.invalidOTP = true;
				this.otpValidated = false;
			});
		} else {
			this.otpService.verifyOTPV1(this.applicationId, this.OTP, this.phone, this.email)
			.subscribe((resp: any) => {
				console.log('VERIFY OTP RESP >>>>>>>>>', resp);
				if (resp && resp.code === 200) {
					this.barButtonOptions.active = false;
					this.invalidOTP = false;
					this.otpValidated = true;

					this.dialogRef.close(true);
				} else {
					this.barButtonOptions.active = false;
					this._matSnackBar.open('Please Enter Valid OTP.', 'Warning !!!', {
						duration: 3000,
					});
					this.invalidOTP = true;
					this.otpValidated = false;
				}
			}, err => {
				this.barButtonOptions.active = false;
				console.log('ERROR >>>>>>', err);
				this._matSnackBar.open('Please Enter Valid OTP.', 'Error !!!', {
					duration: 3000,
					verticalPosition: 'top'
				});
				this.invalidOTP = true;
				this.otpValidated = false;
			});
		}
	}
}
